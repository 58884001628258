import React from 'react';
import Title from '../components/Title';

const Location = ({ data }) => {
  return (
    <>
      <section
        className="location container d-flex flex-column align-items-center"
        data-aos="zoom-in"
        data-aos-duration="1500">
        <Title title="Lokasi" />
        <div className="row mt-3">
          <div className="col">
            <iframe
              title="asi"
              src={data.maps}
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </div>
        </div>
      </section>
      <footer className="footer text-danger text-center p-0 m-0">
        <p> {new Date().getFullYear()} &copy; by Bahyu Sanciko</p>
      </footer>
    </>
  );
};

export default Location;
