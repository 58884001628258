import React from 'react';
import Title from '../components/Title';

const Rundown = ({ data }) => {
  return (
    <div
      className="events container d-flex flex-column justify-content-center align-items-center"
      data-aos={'zoom-in-right'}
      id="rundown"
      data-aos-duration="1500">
    <div className="container-fluid">
      <Title title="Rundown Acara" />
      <ul className="list-group list-group-flush" style={{ backgroundColor: 'transparent' }}>
        {data.map((item, index) => (
          <li key={index} className="list-group-item" style={{ backgroundColor: 'transparent' }} >{item.time} : {item.activity}</li>
        ))}
      </ul>
    </div>
    </div>
  );
};

export default Rundown;