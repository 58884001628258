export const data = {
  en: {
    config: {
      name: 'PT. SANTINILESTARI CAKRA SENTOSA',
      bgm: 'https://ia802805.us.archive.org/4/items/MarryYourDaughter_525/BrianMcknight-MarryYourDaughterlyrics.mp3',
    },
    header: {
      bgImageLandscape: [require('./assets/images/bg-1.jpg')],
      bgImagePortrait: [require('./assets/images/bg-2.jpeg')],
      bridegroom: 'SYUKURAN PEMBUKAAN',
      desc: 'PT. SANTINILESTARI CAKRA SENTOSA',
      date: 'Senin, 7 Oktober 2024',
      timestamp: new Date('2024-10-07').getTime(),
      ongoing: 'BERLANGSUNG',
      days: 'HARI',
      hours: 'JAM',
      minutes: 'MENIT',
      seconds: 'DETIK',
      line1: 'Kepada Yth :',
      line2: 'Kami mengundang anda dalam acara Syukuran Pembukaan PT. SCS',
      button: 'Buka Undangan',
    },
    navbar: {
      brand: 'SCS',
      nav: [
        { to: 'header', text: 'Beranda' },
        { to: 'events', text: 'Acara' },
        { to: 'rundown', text: 'Rundown Acara' },
        { to: 'location', text: 'Lokasi' },
      ],
    },
    events: {
      reception: {
        type: 'reception',
        foto: require('./assets/images/events/reception.jpg'),
        title: 'ACARA',
        date: 'Senin, 7 Oktober 2024',
        time: 'Bertempat di :',
        address: {
          line1: 'PT. SANTINILESTARI CAKRA SENTOSA',
          line2: 'Jl. Soekarno Hatta No.2/210',
          line3: 'Labuhan Ratu Raya, Kota Bandar Lampung',
          line4: 'Lampung 35141'
        },
      },
    },
    location: {
      maps: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3972.3528333088952!2d105.2587872!3d-5.3630268999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e40c5003036131d%3A0x122610656a29f071!2sPT.%20SANTINILESTARI%20CAKRA%20SENTOSA!5e0!3m2!1sen!2sid!4v1726723279748!5m2!1sen!2sid',
    },
    rundown: [
      { time: '12.00 - 12.05', activity: 'Pembukaan' },
      { time: '12.05 - 12.45', activity: 'Sambutan Para Pemegang Saham' },
      { time: '12.45 - 13.00', activity: 'Pembacaan Doa' },
      { time: '13.00 - 13.10', activity: 'Pemotongan Tumpeng' },
      { time: '13.10 - Selesai', activity: 'Makan bersama' },
    ]
  },
};
